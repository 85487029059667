import { render, staticRenderFns } from "./collapse-tab.vue?vue&type=template&id=a5d24ebe&scoped=true"
import script from "./collapse-tab.vue?vue&type=script&lang=js"
export * from "./collapse-tab.vue?vue&type=script&lang=js"
import style0 from "./collapse-tab.vue?vue&type=style&index=0&id=a5d24ebe&prod&lang=scss"
import style1 from "./collapse-tab.vue?vue&type=style&index=1&id=a5d24ebe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5d24ebe",
  null
  
)

export default component.exports