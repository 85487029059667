import Vue from "vue";
import Router from "vue-router";
import routers from "./router";
import constRouters from "./constRouter";

import layout from "../layouts/index";
Vue.use(Router); //vue全局使用Router
export default new Router({
	mode: 'history',
	base: '/pc/',
	routes: [{
			path: "/",
			meta: {
				title: "",
				content: {
					keywords: "",
					description: "",
				},
			},
			redirect: "/index",
			component: () => import("../pages/index/index.vue"),
		},
		// 一级路由出口
		{
			path: "/stair",
			name: "一级路由出口",
			component: () => import("../views/stair.vue"),
			redirect: "/personalcenter",
			children: [{
					path: "/personalcenter",
					name: "个人中心",
					component: () => import("../PersonalCenter/index.vue"),
					redirect: "/basics",
					children: [{
							path: "/basics",
							name: "基本信息",
							component: () => import("../PersonalCenter/basics.vue"),
						},

						// 政策
						{
							path: "/information",
							name: "政策信息",
							component: () => import("../PersonalCenter/information.vue"),
						},
						// 专利管理
						{
							path: "/zlManage",
							name: "专利管理",
							component: () => import("../PersonalCenter/zlManage.vue"),
						},
						// 年费监控
						{
							path: "/yearJk",
							name: "年费监控",
							component: () => import("../PersonalCenter/yearJk.vue"),
						},
						// 商标管理
						{
							path: "/sbManage",
							name: "商标管理",
							component: () => import("../PersonalCenter/sbManage.vue"),
						},
						// 版权管理
						{
							path: "/bqManage",
							name: "版权管理",
							component: () => import("../PersonalCenter/bqManage.vue"),
						},
						// 已获得政策
						{
							path: "/yinformation",
							name: "已获得政策",
							component: () => import("../PersonalCenter/yinformation.vue"),
						},
						// 文件管理
						{
							path: "/documentManage",
							name: "文件管理",
							component: () => import("../PersonalCenter/documentManage.vue"),
						},
						// 资质管理
						{
							path: "/zzManage",
							name: "资质管理",
							component: () => import("../PersonalCenter/zzManage.vue"),
						},
						// 正在申报
						{
							path: "/sbNow",
							name: "正在申报",
							component: () => import("../PersonalCenter/sbNow.vue"),
						},
						//   知识产权
						{
							path: "/knowledge",
							name: "知识产权",
							component: () => import("../PersonalCenter/knowledge.vue"),
						},
						{
							path: "/record",
							name: "年费缴纳",
							component: () => import("../PersonalCenter/record.vue"),
						},
						//   诉求管理
						{
							path: "/appeal",
							name: "诉求记录",
							component: () => import("../PersonalCenter/appeal.vue"),
						},
						//   申请记录
						{
							path: "/annual",
							name: "申请记录",
							component: () => import("../PersonalCenter/annual.vue"),
						},
					],
				},
				{
					path: "/lwDetail",
					component: () => import("../pages/expert/lwDetail"),
				},
				{
					path: "/paperDetail",
					component: () => import("../pages/policy/paperDetail"),
				},{
					path: "/dynamicList",
					component: () => import("../pages/index/dynamicList"),
				}, {
					path: "/qysb",
					component: () => import("../pages/policy/qysb"),
				}, {
					path: "/cqzx",
					component: () => import("../knowledge/cqzx"),
				}, {
					path: "/ysMent",
					component: () => import("../pages/ment/ysMent.vue"),
				}, {
					path: "/bindInfo",
					component: () => import("../pages/bindInfo/bindInfo"),
				}, {
					path: "/policyList",
					component: () => import("../pages/index/policyList"),
				}, {
					path: "/activityDetail",
					component: () => import("../pages/index/activityDetail"),
				}, {
					path: "/joinActivity",
					component: () => import("../pages/index/joinActivity"),
				}, {
					path: "/trendsDetail",
					component: () => import("../pages/policy/trendsDetail"),
				}, {
					path: "/companyConsultation",
					component: () => import("../pages/index/companyConsultation"),
				}, {
					path: "/policyListData",
					component: () => import("../pages/policy/policyListData"),
				}, {
					path: "/policyDetailData",
					component: () => import("../pages/policy/policyDetailData"),
				}, {
					path: "/knowledgeDetail",
					component: () => import("../pages/policy/knowledgeDetail"),
				}, {
					path: "/knowledgeList",
					component: () => import("../pages/policy/knowledgeList"),
				}, {
					path: "/enterprise",
					component: () => import("../pages/enterprise/enterprise"),
				},
				{
					path: "/subEnterprise",
					component: () => import("../pages/enterprise/subEnterprise"),
				},
				{
					path: "/enterpriseDetail",
					component: () => import("../pages/enterprise/enterpriseDetail"),
				},
				{
					path: "/finance",
					component: () => import("../pages/finance/finance"),
				},
				{
					path: "/serviceList",
					component: () => import("../pages/finance/serviceList"),
				},
				{
					path: "/serviceDetail",
					component: () => import("../pages/finance/serviceDetail"),
				},
				{
					path: "/subService",
					component: () => import("../pages/finance/subService"),
				},
				{
					path: "/register",
					component: () => import("../pages/register/register"),
				},
				{
					path: "/forgetPassWord",
					component: () => import("../pages/forgetPassWord/forgetPassWord"),
				},
				{
					path: "/expert",
					component: () => import("../pages/expert/expert"),
				},
				{
					path: "/zxsq",
					component: () => import("../pages/expert/zxsq"),
				},
				{
					path: "/expertList",
					component: () => import("../pages/expert/expertList"),
				},
				{
					path: "/expertDetail",
					component: () => import("../pages/expert/expertDetail"),
				},
				{
					path: "/expertRz",
					component: () => import("../pages/expert/expertRz"),
				},
				{
					path: "/zlDetail",
					name: "专利详情",
					component: () => import("../pages/expert/zlDetail"),
				},
				{
					path: "/index",
					component: () => import("../pages/index/index.vue"),
				},

				// 基本资料
				{
					path: "/email",
					name: "更改邮箱",
					component: () => import("../PersonalCenter/first/email.vue"),
				},
				{
					path: "/phone",
					name: "更改手机号",
					component: () => import("../PersonalCenter/first/phone.vue"),
				},
				{
					path: "/wechat",
					name: "更改微信",
					component: () => import("../PersonalCenter/first/wechat.vue"),
				},
				{
					path: "/firm",
					name: "企业信息",
					component: () => import("../PersonalCenter/first/firm.vue"),
				},
				// 知识产权
				{
					path: "/equity",
					name: "知识产权",
					component: () => import("../knowledge/index.vue"),
				},
				{
					path: "/company",
					name: "公司",
					component: () => import("../knowledge/company.vue"),
				},
				{
					path: "/intellect",
					name: "智能匹配",
					component: () => import("../knowledge/intellect.vue"),
				}, {
					path: "/knowPage",
					name: " 后台知识产权 ",
					component: () => import("../knowledge/knowPage.vue"),
				},
				// 人才库
				{
					path: "/person",
					name: "人才库",
					component: () => import("../person/index.vue"),
				},
				{
					path: "/personlist",
					name: "人才列表",
					component: () => import("../person/personlist.vue"),
				},
				{
					path: "/persondetail",
					name: "人才详情",
					component: () => import("../person/persondetail.vue"),
				},
				{
					path: "/personsubmit",
					name: "提交申请",
					component: () => import("../person/personsubmit.vue"),
				},
				// 供需市场
				{
					path: "/gongxu",
					name: "供需市场",
					component: () => import("../supply/index.vue"),
				},
				{
					path: "/identity",
					name: "身份申请",
					component: () => import("../supply/identity.vue"),
				},
				{
					path: "/offer",
					name: "我提供",
					component: () => import("../supply/offer.vue"),
				},
				{
					path: "/productList",
					name: "产品列表",
					component: () => import("../supply/productList.vue"),
				},
				{
					path: "/productDetail",
					name: "产品详情",
					component: () => import("../supply/productDetail.vue"),
				},
				{
					path: "/leave",
					name: "产品详情-留言咨询",
					component: () => import("../supply/leave.vue"),
				},
				{
					path: "/demandList",
					name: "需求列表",
					component: () => import("../supply/demandList.vue"),
				},
				{
					path: "/demandDetail",
					name: " 需求详情 ",
					component: () => import("../supply/demandDetail.vue"),
				},
				{
					path: "/industryDetail",
					name: "行业动态详情",
					component: () => import("../supply/industryDetail.vue"),
				},
				{
					path: "/industryList",
					name: "行业动态列表",
					component: () => import("../supply/industryList.vue"),
				},
				{
					path: "/mybuy",
					name: " 我需求 ",
					component: () => import("../supply/myBuy.vue"),
				},
				// 惠企服务
				{
					path: "/serve",
					name: "惠企服务",
					component: () => import("../serve/index.vue"),
				},
				{
					path: "/policyDetail",
					name: "政策库详情",
					component: () => import("../pages/policy/policyDetail"),
				},
				{
					path: "/policyData",
					name: "政策库",
					component: () => import("../pages/policy/policyData"),
				},
				{
					path: "/match",
					name: "政策匹配",
					component: () => import("../pages/match/match"),
				},
				{
					path: "/report",
					name: "惠企服务详情",
					component: () => import("../serve/report.vue"),
				},
				{
					path: "/apply",
					name: " 惠企服务-申请服务 ",
					component: () => import("../serve/apply.vue"),
				},
				//企业库
				{
					path: "/enterpriseLibrary",
					name: " 企业库 ",
					component: () => import("../pages/enterpriseLibrary/enterpriseLibrary.vue"),
				},
			],
		}, {
			path: "/dataPage/page1",
			meta: {
				title: "数据大屏",
			},
			component: () => import('@/pages/dataPage/page1/index.vue'),
		}
	],
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0,
		};
	},
});

function exportWhiteListFromRouter(router) {
	let res = [];
	for (let item of router) res.push(item.path);
	return res;
}
let router = new Router({
	mode: 'history',
	base: '/pc/',
	routes: constRouters,
});

function save(name, data) {
	localStorage.setItem(name, JSON.stringify(data));
}
router.addRoutes(routers);
// 路由白名单
const whiteList = exportWhiteListFromRouter(constRouters);
export function resetRouter() {
	location.reload();
}