import index from '../pages/index/index'
var routers = [
  { 
	path: "/index",
    name: "主页",
    component: index,
    icon: "none",
    redirect: "/index",//重定向至/login
  },
 
]
export default routers