// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
import * as utils from "./utils/index.js";
import * as signout from './utils/signout.js'
import utilsnew from './utils/utils.js'
import AnimatedNumber from "animated-number-vue";
import dayjs from 'dayjs'
import "./style/index.css"
import './style/css.scss'
import './style/scroll.css'

Vue.use(dayjs);
Vue.use(utilsnew);

import '@/config/permission'
// import Vab from '@/utils/vab'
// Vue.use(Vab)

import ElementUI from 'element-ui'
import {
	ElementTiptapPlugin
} from 'element-tiptap';
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';

// 引用axios
import api from './api/index.js'
Vue.prototype.$api = api
import config from './config/net.config.js'
Vue.prototype.$utils = utils
Vue.prototype.$signout = signout
import user from './store/modules/user';
import axios from 'axios';
Vue.prototype.$axios = axios;
import "./style/index.scss"
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, {
	lang: 'zh', // 设置语言为中文
});
import index from '@/components/index.js'
Vue.prototype.$upload = 'http://www.tianaoems.com/api/common/upload/'

new Vue({
	router,
	store,
	components: {
		App
	},
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	},
	render: h => h(App)
}).$mount("#app")