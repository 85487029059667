<template>
	<div :class="isTm?'fullTop':'fullTops'">
		<div class="parent">
			<div class="center flexbetween">
				<div class="left flexleft">
					<div class="tag" style="margin-right: 1.2rem;" @click="addBookmarkCompat">收藏本页</div>
					<div class="tag tager">
						手机版入口
						<div class="erwm">
							<img src="../../src/assets/img/xcx.jpg" alt="">
						</div>
					</div>
				</div>
				<div class="right flexleft">
					<div v-if="$store.state.userinfo == null" class="tag" @click="login">会员登录</div>
					<div v-else class="tag">
						您好，{{ $store.state.userinfo.nickname || '--' }}
						<span class="line">|</span>
						<span @click="outo">退出</span>
					</div>
					<div v-if="$store.state.userinfo == null" class="tag" @click="jump('register')"><span class="line">|</span>账号注册</div>
					<div class="line">|</div>
					<div class="tag" @click="toChangePass">忘记密码</div>
				</div>
			</div>
		</div>
		<div class="navList" :style="{background:background}">
			<div class="center flexbetween">
				<div class="left flexleft">
					<div class="logo">
						<img src="../../src/assets/img/headerLogo.png" alt="">
					</div>
				</div>
				<div class="right flexleft">
					<div v-for="(item,index) in navList" :key="index" class="tag"
						:class="current == index ? 'active' : ''" @click="changeTab(index)">
						{{ item }}
					</div>
				</div>
			</div>
		</div>
		<qrcodes ref="login" :show="show" />
	</div>
</template>

<script>
	export default {
		name: 'Headers',
		props: {
			current: {
				default: 0,
				type: [String, Number],
			},
			isTm: {
				default: false,
				type: Boolean,
			}
		},
		data() {
			return {
				userinfo: JSON.parse(localStorage.getItem('userinfo')) || '',
				show: false,
				background: 'rgba(255, 255, 255, 1)',
				scrollPosition: '',
				navList: ['首页', '政策库', '政策匹配', '知识产权', '惠企服务', '专家库', '人才库', '融资服务', '企业诉求', '供需市场', '个人中心'],
			};
		},
		mounted() {
			if (localStorage.getItem('userId')) {
				this.getUserInfo();
			}
			if (this.isTm) {
				window.addEventListener('scroll', this.updateScroll);
			} else {
				this.background = 'rgba(255, 255, 255, 1)'
			}
		},
		methods: {
			toChangePass(){
				let user = JSON.parse(localStorage.getItem('userinfo')) || '';
				// if(!user){
				// 	this.$refs.login.show();
				// 	return
				// }
				if(user && !user.email && !user.mobile){
					this.$message({
						message: '请先绑定手机号或邮箱',
						offset:150,
					});
					return
				}
				window.open(`forgetPassWord`)
				// this.$router.push({path:'forgetPassWord'})
			},
			outo() {
				localStorage.clear();
				this.$store.commit('setUserInfo',null);
				if(this.$route.fullPath != '/index'){
					this.$router.push('index');
					// window.open(`index`)
				} else {
					this.$router.go(0);
				}
			},
			addBookmarkCompat() {
				const url = window.location.href;
				const title = document.title;
				try {
					// 尝试使用IE的方式添加书签
					window.external.AddFavorite(url, title);
				} catch (e) {
					// 如果不支持，则提醒用户手动添加
					this.addBookmark();
				}
			},
			addBookmark() {
				this.$message({
					message:'请按Ctrl+D(Windows)或Cmd+D(Mac)来收藏本页。',
					offset:150,
					type: 'warning'
				});
			},
			getUserInfo() {
				if (!localStorage.getItem('userId')) return;
				let _this = this;
				_this.$api.yms.user_detail({
					user_id: localStorage.getItem('userId'),
				}).then(e => {
					_this.$store.commit('setUserInfo',e.data);
					localStorage.setItem('userinfo', JSON.stringify(e.data));
					if(e.data.notice_time != null){
						localStorage.setItem('isNotice', e.data.notice_time);
					}
					if (e.data.user_id) {
						localStorage.setItem('userId', e.data.user_id);
					}
					if (e.data.qyedit && e.data.qyedit.status == 2) {
						localStorage.setItem('qyId', e.data.qyedit.enterprise_id);
						return
					}
					if (e.data.qiyeauthentication && e.data.qiyeauthentication.enterprise_id) {
						localStorage.setItem('qyId', e.data.qiyeauthentication.enterprise_id);
					}
				})
			},
			jump(url) {
				// this.$router.push(url);
				window.open(url)
			},
			changeTab(index) {
				let url;
				if (index == 0) {
					url = 'index';
				} else if (index == 1) {
					url = 'policyData';
				} else if (index == 2) {
					if (!localStorage.getItem('userId')) {
						this.$message({
							message:'请先登录',
							offset:150,
							type: 'warning'
						});
						return;
					}
					if (!localStorage.getItem('qyId')) {
						this.$message({
							message:'请到个人中心补全企业信息',
							offset:150,
							type: 'warning'
						});
						return ;
					}
					url = 'match';
				} else if (index == 3) {
					url = 'equity';
				} else if (index == 4) {
					url = 'serve';
				} else if (index == 5) {
					url = 'expert';
				} else if (index == 6) {
					url = 'person';
				} else if (index == 7) {
					url = 'finance';
				} else if (index == 8) {
					url = 'enterprise';
				} else if (index == 9) {
					url = 'gongxu';
				} else if (index == 10) {
					// url = 'personalcenter';
					if (!localStorage.getItem('userId')) {
						this.$refs.login.show();
						return;
					}
					url = 'basics';
				}
				if (this.$route.path != `/${url}`) {
					this.$router.push(`/${url}`);
				}
				// this.$router.push('/' + url)
			},
			login() {
				this.$refs.login.show();
			},
			updateScroll() {
				this.scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
				if (this.scrollPosition >= 350) {
					this.background = 'rgba(255, 255, 255, 1)'
				} else {
					// this.background = 'rgba(255, 255, 255, 0.3)'
					this.background = 'rgba(255, 255, 255, 1)'
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.fullTop {
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;
	}
	::v-deep .v-modal{
		z-index: 1999 !important;
	}

	.navList {
		background-color: rgba(255, 255, 255, 0.3);
		padding: 1rem 0;

		.center {
			width: 75%;
			margin: auto;
		}

		.logo {
			width: 8rem;
			height: 1.5rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tag {
			margin: 0 0.8rem;
			cursor: pointer;
			font-weight: bold;
			color: #1A1A1A;
			font-size: 1.1rem;
			min-width: fit-content;
		}

		.tag:last-child {
			margin: 0 0 0 1.2rem;
		}

		.tag:hover {
			color: #217BFF;
		}

		.active {
			color: #297FFF;
		}
		
		@media screen and (max-width: 1550px) {
			.center {
				width: 95%;
			}
		}
		@media screen and (max-width: 960px) {
			.center {
				width: 100%;
			}
		}
	}

	.parent {
		background-color: #fff;
		padding: 0.6rem 0;

		.center {
			width: 75%;
			margin: auto;

			.left {
				.tager {
					position: relative;

					.erwm {
						display: none;
						position: absolute;
						left: 50%;
						top: calc(100% + 0.5rem);
						background-color: #fff;
						border-radius: 0.6rem;
						box-shadow: 0 0 .5rem .1rem #ccc;
						padding: 0.6rem;
						transform: translateX(-50%);

						img {
							width: 10rem;
							height: 10rem;
						}
					}
				}

				.tag:hover .erwm {
					display: block;
				}
			}
		}
		.tag {
			font-size: 1rem;
			cursor: pointer;
			color: #111;
		}
		@media screen and (max-width: 1550px) {
			.center {
				width: 95%;
			}
		}
		@media screen and (max-width: 960px) {
			.center {
				width: 100%;
			}
		}

		.line {
			font-size: 1rem;
			margin: 0 0.4rem;
			color: #333 !important;
		}
	}
	
</style>