import router from '../router/index'
// import store from '../store'
// import $api from '../api/api';
import $https from '../api/index';

var lastTime = new Date().getTime()
var currentTime = new Date().getTime()
var timeOut = 60 * 60 * 1000 // 

window.onload = function() {
	window.document.onmousedown = function() {
		localStorage.setItem('lastTime', new Date().getTime())
	}
}

export function checkTimeout() {
	currentTime = new Date().getTime() // 更新当前时间
	lastTime = localStorage.getItem('lastTime')
	if (lastTime) {
		if (currentTime - lastTime > timeOut) { // 判断是否超时 ,在这里面写退出的一些操作
			// var url = window.location.href
			// var newUrl = url.match(/(\S*)#/)[1]
			// window.open(newUrl + '#/login', '_self')
			// logout() //本人项目中的退出方法
		}
	}

}
//退出方法
export function logout() {
	var api = $api.api.login.logout;
	var sign = localStorage.getItem("sign");
	$https.post({
			type: "formData",
			api: api,
		}, {
			sign: sign,
		},
		(response) => {
			alert(response.msg)
		},
		(error) => {
			console.log(error);
		}
	);
	localStorage.clear();
	router.go(0);
}
/* 定时器 间隔30秒检测是否长时间未操作页面 */
setInterval(checkTimeout, 30000)