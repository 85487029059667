<template>
  <div
    v-loading="loading"
    style="margin: 0px 10px; display: inline-block"
    @click="exportFile"
  >
    <slot />
  </div>
</template>

<script>
	// import config from '@/config'
	// const {
	// 	baseURL,
	// 	oss
	// } = config

	// const exportapi = ''
	// require('@/api/exportapi')
	export default {
		name: 'ExportButton',
		props: {
			searchform: {
				type: Object,
				default: () => ({})
			},
			type: {
				type: String,
				default:""
			},
			api: {
				type: String,
				default:""
			},
		},
		data() {
			return {
				loading: false,
			}
		},
		created() {},
		methods: {
			async exportFile() {
				console.log(this.$api.yms[this.api], this.api, 999)
				this.loading = true
				try {
					let data = await this.$api.yms[this.api]({
						...this.searchform,
						is_export: 1
					})
					console.log(data, 66)
					if (data) {
						this.downLoad(data)
					}
				} catch (e) {
					//TODO handle the exception
					console.error('导出出错', e)
				}
				this.loading = false
			},
			downLoad(filesrc) {
				let index = filesrc.lastIndexOf('/')
				let filename = filesrc.substring(index)
				console.log('index:', index + 1, filename)
				var eleLink = document.createElement('a')
				eleLink.download = filename
				eleLink.style.display = 'none'
				// var blob = new Blob([this.filesrc]);
				// eleLink.href = URL.createObjectURL(blob);
				eleLink.href = filesrc
				eleLink.setAttribute('target', '_blank')
				document.body.appendChild(eleLink)
				eleLink.click()
				document.body.removeChild(eleLink)
			},
		},
	}
</script>

<style></style>