import request from "@/utils/request.js";

// 大屏  获取地图信息
export function get_total_map(data) {
  return request({
    url: "/api/daping/get_total_map",
    // url: "https://ht.hqzcb.com/api/Webgrab/get_total_map",
    method: "get",
    params: data,
    // data,
  });
}

// https://xa.daoben.tech/api/demo/get_total_map?area_name=

//大屏  获取政策信息
export function get_policy(data) {
  return request({
    url: "/api/daping/get_policy",
    method: "post",
    data
    // params: data,
  });
}

//大屏  知识产权
export function knowledge_list(data) {
  return request({
    url: "/api/daping/knowledge_list",
    method: "POST",
    data,
  });
}
 
//数据分析
export function analysis(data) {
  return request({
    url: "/api/daping/analysis",
    method: "POST",
    data,
  });
}


//数据分析
export function get_year(data) {
  return request({
    url: "/api/daping/get_year",
    method: "POST",
    data,
  });
}

//数据分析
export function ttttt(data) {
  return request({
    url: "/api/daping/ttttt",
    method: "POST",
    data,
  });
}




