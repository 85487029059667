import Vue from "vue";
import axios from "axios";

import config from "@/config";
const {
	baseURL,
	contentType,
	debounce,
	invalidCode,
	noPermissionCode,
	requestTimeout,
	successCode,
	tokenName,
	loginInterception,
} = config;

import store from "@/store";
import qs from "qs";
import router from "@/router";
import {
	isArray
} from "@/utils/validate";

let loadingInstance;

/**
 * @author https://gitee.com/chu1204505056/vue-admin-better （不想保留author可删除）
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
	switch (code) {
		case invalidCode:
			Vue.prototype.$message.error(msg || `后端接口${code}异常`, "error");
			store.dispatch("user/resetAccessToken").catch(() => {});
			if (loginInterception) {
				location.reload();
			}
			break;
		case noPermissionCode:
			store.dispatch("user/resetAccessToken");
			router
				.push({
					path: "/login",
				})
				.catch(() => {});
			break;
		default:
			Vue.prototype.$message.error(msg || `后端接口${code}异常`, "error");
			break;
	}
};

const instance = axios.create({
	baseURL,
	timeout: requestTimeout,
	headers: {
		"Content-Type": contentType,
		Authorization: "Basic REJfV0VCOkRCX1dFQg==",
		// 'access-control-allow-origin'
	},
});

instance.interceptors.request.use(
	(config) => {
		try {
			if (store.getters["user/accessToken"]) {
				config.headers["token"] = store.getters["user/accessToken"];
				config.headers[
					"Authorization"
				] = `Bearer ${store.getters["user/accessToken"]}`;
				config.headers["HbnAccessToken"] = store.getters["user/accessToken"];
				config.headers["HbnRefreshToken"] =
					store.getters["user/HbnRefreshToken"];
				config.headers["HbnExpiresIn"] = store.getters["user/HbnExpiresIn"];
			} else {
				config.headers["isToken"] = false;
			}
			config.data["user_id"] = localStorage.getItem('userId') || '';
			// config.data["bindenterprise_id"] =
			// localStorage.getItem("bindenterprise_id");
		} catch (error) {}
		//这里会过滤所有为空、0、false的key，如果不需要请自行注释
		// if (config.data)
		//   config.data = Vue.prototype.$baseLodash.pickBy(
		//     config.data,
		//     Vue.prototype.$baseLodash.identity
		//   )
		if (
			config.data &&
			config.headers["Content-Type"] ===
			"application/x-www-form-urlencoded;charset=UTF-8"
		)
			config.data = qs.stringify(config.data);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (loadingInstance) loadingInstance.close();
		const {
			data,
			config
		} = response;
		const {
			code,
			msg
		} = data;

		// 操作正常Code数组
		const codeVerificationArray = isArray(successCode) ? [...successCode] : [...[successCode]];
		// 是否操作正常
		if (data.access_token) {
			// 登录接口没有状态码
			return data;
		} else if (response.request.responseType == "blob") {
			const blob = new Blob([data]);
			const postData = response.config.data ?
				JSON.parse(response.config.data) :
				"";

			const ltyp = postData.path.split(",")[1] ?
				postData.path.split(",")[1] :
				"xls";
			const fileName = postData.filename ?
				postData.filename + "." + ltyp :
				"表格.xls";
			if ("download" in document.createElement("a")) {
				// 非IE下载
				const elink = document.createElement("a");
				elink.download = fileName;
				elink.style.display = "none";
				elink.href = URL.createObjectURL(blob);
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href); // 释放URL 对象
				document.body.removeChild(elink);
			} else {
				// IE10+下载
				navigator.msSaveBlob(blob, fileName);
			}
		} else if (code == 401) {
			store.dispatch("user/resetAccessToken");
		} else if (codeVerificationArray.includes(code)) {
			return data;
		} else {
			return data;
			// return Promise.reject(
			// 	'请求异常拦截:' +
			// 	JSON.stringify({
			// 		url: config.url,
			// 		code,
			// 		msg
			// 	}) || 'Error'
			// )
		}
	},
	(error) => {
		if (loadingInstance) loadingInstance.close();
		const {
			response,
			message
		} = error;
		if (error.response && error.response.data) {
			const {
				status,
				data
			} = response;
			handleCode(status, data.msg || message);
			return Promise.reject(error);
		} else {
			let {
				message
			} = error;
			if (message === "Network Error") {
				message = "后端接口连接异常";
			}
			if (message.includes("timeout")) {
				message = "后端接口请求超时";
			}
			if (message.includes("Request failed with status code")) {
				const code = message.substr(message.length - 3);
				message = "后端接口" + code + "异常";
			}
			Vue.prototype.$message.error(message || `后端接口未知异常`, "error");
			return Promise.reject(error);
		}
	}
);

export default instance;