import Vue from 'vue';
import headers from './header.vue'
import footers from './footer.vue'
import qrcodes from './qrcode.vue'
import exportBtn from './export-button.vue'
import collapseTab from './collapse-tab/collapse-tab.vue'


Vue.component('Headers',headers);
Vue.component('Qrcodes',qrcodes);
Vue.component('ExportBtn',exportBtn);
Vue.component('Footers',footers);
Vue.component('collapseTab',collapseTab);