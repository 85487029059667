// import $api from '../api/api';
import $https from '../api/index';
import $store from '../store';
import config from '@/config/net.config';
// 时间戳
export function formatDate(time) {
	var now = new Date(time);
	var year = now.getFullYear();
	var month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);
	var day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
	var hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
	var minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
	var second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
	let data = {
		year,
		month,
		day,
		hour,
		minute,
		second,
		time: year + '-' + month + '-' + day + ' ' + hour + ':' + minute
	}
	return data;
}

//验证手机号
export function mobile(phoneNumber) {
	const landlineRegex = /^(\d{3,4}-)?\d{7,8}$/;
	const mobileRegex = /^1[3-9]\d{9}$/;
	// let a = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(num);
	return landlineRegex.test(phoneNumber) || mobileRegex.test(phoneNumber);;
}

//验证身份证号
export function idCard(num) {
	let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(num);
	return reg
}

export function checkKeyword(title, keyword) {
	const index = title.indexOf(keyword);
	if (index !== -1 && index + keyword.length === title.indexOf(keyword, index + 1)) {
		// 关键词完整且连续  
		return {
			hasKeyword: true,
			startIndex: index,
			length: keyword.length
		};
	}
	// 检查是否有连续的子串
	let maxLength = 0;
	let startIndex = -1;
	for (let i = 0; i <= title.length - keyword.length; i++) {
		let currentLength = 0;
		for (let j = 0; j < keyword.length; j++) {
			if (title[i + j] === keyword[j]) {
				currentLength++;
			} else {
				break;
			}
		}
		if (currentLength > maxLength) {
			maxLength = currentLength;
			startIndex = i;
		}
	}
	// 返回连续子串的信息（如果不完整且连续，或者没有连续子串，则maxLength为0）  
	return {
		hasKeyword: false,
		startIndex: startIndex,
		length: maxLength
	};
}

//时间戳转换
export function getDates(timestamp) {
	if (String(timestamp).indexOf('-') != -1) {
		return timestamp
	}
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	return Y + M + D;
}


//时间戳转换
export function getDatess(timestamp) {
	if (String(timestamp).indexOf('-') != -1) {
		return timestamp
	}
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	return Y + M + D + '' + h + m;
}

export function big(num, type = '') {
	if (!num) return 0;
	const strNum = Number((num + '').replace(/[,，]*/g, '')) + ''; // 记录字符
	num = parseInt(Number(strNum)); // 转为整数，
	let capitalAr = '零一二三四五六七八九十';
	let unitAr = ['十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千'];
	if (type) {
		capitalAr = '零壹贰叁肆伍陆柒捌玖拾';
		unitAr = ['拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟']; // 单位
	}
	const resultAr = []; // 记录结果，后边json.in就可
	let index = strNum.length - 1; //记录位数
	let idx = 0; // 记录单位
	let percent = 10;
	const turnNum = (num, percent, index) => {
		const unit = num / percent;
		const capital = capitalAr[Number(strNum[index])];
		if (unit < 1) {
			resultAr.push(capital);
			// 出现11【一十一】这种情况
			if (Number(strNum[index]) === 1 && (strNum.length === 2 || strNum.length === 6 || strNum.length ===
					10)) {
				resultAr.pop();
			}
			return false //结束递归
		} else {
			if (capital === '零') {
				// 万和亿单位不删除
				if (!['万', '亿'].includes(resultAr[resultAr.length - 1])) {
					resultAr.pop();
				}
				// 前面有零在删掉一个零
				if (resultAr[resultAr.length - 1] === '零') {
					resultAr.pop();
				}
			}
			resultAr.push(capital)
			// 过滤存在【零万】【零亿】这种情况
			if (['万', '亿'].includes(resultAr[resultAr.length - 2]) && capital === '零') {
				resultAr.pop();
			}
			// 过滤【1亿万】这种情况
			if (resultAr[0] === '万' && resultAr[1] === '亿') {
				resultAr.shift();
			}
			// 末尾【零】删掉
			if (resultAr[0] === '零') {
				resultAr.pop();
			}
			resultAr.push(unitAr[idx++]);
			turnNum(num, percent * 10, --index);
		}
	}
	turnNum(num, percent, index);
	return resultAr.reverse().join('');
}

export function success(msg) {
	this.$message({
		message: msg,
		type: 'success',
		offset: 150,
	});
}

export function warning(msg) {
	this.$message({
		message: msg,
		type: 'warning',
		offset: 150,
	});
}

//失败
export function baseMessage(msg) {
	this.$message.error({
		message: msg,
		offset: 150,
	});
}

export function changeSec(seconds) {
	const hours = Math.floor(seconds / 3600) > 9 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60) > 9 ? Math.floor((seconds % 3600) / 60) : '0' + Math.floor((
		seconds % 3600) / 60);
	const remainingSeconds = seconds % 60 > 9 ? seconds % 60 : '0' + seconds % 60;

	return hours + ":" + minutes + ":" + Math.round(remainingSeconds);
}

export function phone(str) {
	if (null != str && str != undefined) {
		let star = '' //存放名字中间的*
		if (str.length <= 2) {
			return str.substring(0, 1) + "*";
		} else {
			for (var i = 0; i < str.length - 1; i++) {
				star = star + '*'
			}
			return str.substring(0, 3) + star;
		}
	}
}

export function name(str) {
	if (null != str && str != undefined) {
		let star = '' //存放名字中间的*
		if (str.length <= 2) {
			return str.substring(0, 1) + "*";
		} else {
			for (var i = 0; i < str.length - 1; i++) {
				star = star + '*'
			}
			return str.substring(0, 1) + star;
		}
	}
}

export function zdate(e) {
	// 创建一个新的Date对象，传入要判断的日期参数
	var date = new Date(e); // 这里以'2021年9月30日'作为示例日期

	// 调用getDay()方法获取星期几的索引值
	var dayOfWeekIndex = date.getDay();

	// 根据索引值转换成相应的星期字符串
	var daysOfWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
	var dayOfWeekString = daysOfWeek[dayOfWeekIndex];

	return dayOfWeekString;
}


//时间戳转换
export function getDate(timestamp) {
	var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '年';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	return Y + M + D;
}

//验证邮箱
export function email(num) {
	let emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(num)
	return emailRegExp
}

// Vue.prototype.$utils.addurl = function(url) {
// 	let urltext = url;
// 	if (url) {
// 		if (JSON.stringify(url).indexOf("http") == -1 && url.indexOf("base64") == -1 && url.indexOf(
// 				"localhost") == -1) {
// 			urltext = `${config.baseURL}${url}`;
// 		}
// 	}
// 	return urltext;
// }
export function addurl(value) {
	let url = String(value);
	if (url && (url.indexOf("http") == -1 && url.indexOf("base64") == -1)) {
		url = config.baseURL + url
	}
	return url
}

export function setStorage(name, data) {
	let dataType = typeof data;
	// json对象
	if (dataType === 'object') {
		window.localStorage.setItem(name, JSON.stringify(data));
	}
	// 基础类型
	else if (['number', 'string', 'boolean'].indexOf(dataType) >= 0) {
		window.localStorage.setItem(name, data);
	}
}
// 取出本地存储内容
export function getStorage(name) {
	let data = window.localStorage.getItem(name);
	if (data) {
		try {
			return JSON.parse(data);
		} catch (e) {
			//TODO handle the exception
			return data;
		}
	} else {
		return '';
	}
}
// 删除本地存储
export function removeStorage(name) {
	window.localStorage.removeItem(name);
}