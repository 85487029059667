<template>
	<div class="parent">
		<div class="total">
			<div class="centers flexbetween">
				<div class="totalLeft">
					<div class="top flexbetween">
						<div class="name">
							<img src="../../src/assets/img/footlogo.png" alt="">
						</div>
						<div class="phone">
							<div class="topPhone">
								{{ config.fwrx }}
							</div>
						</div>
					</div>
					<div class="bot">
						<div class="botTop flexleft">
							<div class="address flexleft">
								<div class="img">
									<img src="../../src/assets/img/address.png" alt="">
								</div>
								<div class="txt">
									地址：{{ config.gsdz }}
								</div>
							</div>
							<div class="address flexleft" style="margin-left: 10rem;">
								<div class="img">
									<img src="../../src/assets/img/hezuo.png" alt="">
								</div>
								<div class="txt">
									商务合作：{{config.swhz}}
								</div>
							</div>
						</div>
						<div class="botTop flexleft">
							<div class="address flexleft">
								<div class="img">
									<img src="../../src/assets/img/phone.png" alt="">
								</div>
								<div class="txt">
									手机：{{config.phone}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="totalRight">
					<div class="img">
						<img :src="$utils.addurl(config.kfimage)" alt="">
					</div>
					<div class="txts">
						<p>扫描二维码</p>
						<p style="font-size: 1.2rem;">
							联系我们
						</p>
					</div>
				</div>
			</div>
			<div class="txts" v-if="$route.fullPath != '/index'">
				免责声明：以上信息基于公开信息得出，由于存在更新延迟，不保证数据与实际数据相符，仅供参考，不构成任何明示或暗示的观点或保证。<br>
				隐私服务：以上信息虽基于公开信息得出，但我们非常尊重个人隐私和意愿，若企业数据库中联系方式对您带来困扰，请联系客服删除此项。
			</div>
			<div class="link">
				<span class="cur" @click="seeMent(2)">查看用户协议>></span><span class="cur" style="margin: 0 1rem;" @click="seeMent(1)">查看隐私协议>></span><span class="cur" @click="seeMent(3)">公司简介>></span>
			</div>
			
			<div class="last">
				备案号 {{ config.bah }} {{ config.bqxx }}版权所有
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Footers',
		data() {
			return {
				config: '',
			};
		},
		mounted() {
			this.getConfig();
		},
		methods: {
			seeMent(num){
				window.open(`ysMent?status=${num}`)
				// this.$router.push({
				// 	path:'ysMent',
				// 	query:{
				// 		status:num,
				// 	},
				// })
			},
			getConfig() {
				let _this = this;
				_this.$api.yms.pcconfig().then(e => {
					_this.config = e.data;
					localStorage.setItem('config', JSON.stringify(e.data));
				})
			},
		},
	};
</script>

<style scoped lang="scss">
	.link{
		text-align: center;
		color: #fff;
		span{
			border-bottom: 1px solid #fff;
		}
	}
	.txts{
		text-align: center;
		color: #fff;
		padding-bottom: 2rem;
	}
	.last {
		padding: 0.8rem 0;
		background-color: #323232;
		text-align: center;
		color: #787878;
	}

	.total {
		background-color: #323232;
		padding: 0.4rem 0;

		.centers {
			width: 80%;
			margin: auto;
		}

		.totalLeft {
			flex-grow: 1;
		}

		.totalRight {
			margin-left: 2rem;

			.txts {
				margin-top: 0.8rem;
				color: #fff;
				text-align: center;
			}

			.img {
				width: 7.5rem;
				height: 7.5rem;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.bot {
		padding-bottom: 2rem;

		.address {
			margin-top: 1.5rem;

			.txt {
				font-size: 1rem;
				color: #fff;
				margin-left: 1rem;
			}

			.img {
				width: 1.2rem;
				height: 1.2rem;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.center {
		margin: 3rem 0;

		.child {
			cursor: pointer;
			color: #fff;
			margin: 0 2rem;
			width: fit-content;
		}
	}

	.top {
		padding: 1.2rem 0;
		border-bottom: 2px solid #797B88;

		.topPhone {
			font-weight: bold;
			color: #fff;
			font-size: 1.6rem;
		}

		.phoneTxt {
			font-size: 1.1rem;
			color: #fff;
			margin-top: 0.5rem;
		}

		.name {
			align-self: flex-start;
			width: 22rem;
			height: 2rem;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>