/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 23:45:11
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-03-31 18:36:07
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import {
	resetRouter
} from "@/router";
import Cookies from "js-cookie";

Vue.use(Vuex)
const user_info = window.localStorage.getItem('user_info') || null
const token = Cookies.get('token') || null
export default new Vuex.Store({
	state: {
		userinfo:null,
		token: token,
	},
	getters: {
		getToken(state) {
			return state.token
		},
		// getUserInfo(state) {
		// 	return state.userinfo
		// }
	},
	mutations: {
		// 退出登录
		loginout(state) {
			state.token = null
			Cookies.remove('token')
			// router.push('/login')
			this.$router.push({
				path:'login',
			})
		},
		setToken(state, provider) {
			Cookies.set('token', provider, {
				expires: 6 / 24
			}) //token6小时过期
			state.token = provider;
		},
		setUserInfo(state, provider) {
			state.userinfo = provider;
		},
	},
	actions: {},
	modules: {},
})