<template>
	<div>
		<div class="collapse_tab">
			<div class="main_zc">
				<div v-if="showTitle" class="main_tit flex align-start" :style="{ width: width }">
					<div :style="textStyle">{{ title }}</div>
					<!-- <el-popover v-if="num" effect="light" popper-class="poppers" trigger="hover" :content="content"
						placement="right">
						<img slot="reference" style="width: 18px; height: 18px; margin-left: 2px"
							src="../../static/index/question.png" />
					</el-popover> -->
				</div>
				<!-- :class="{ height30: open == true }"  -->
				<div class="tab-list-group" :style="groupStyle">
					<div class="main_text" :id="tablistId">
						<div class="main_box" v-if="$slots.tabListBefore" :style="{ paddingBottom: beforePb }">
							<slot name="tabListBefore"></slot>
						</div>
						<div v-show="item" class="main_box" v-for="(item, index) in list" :key="index">
							<template v-if="!keyName">
								<span :class="{
                    active:
                      (!isCheckBox && isActive === index) ||
                      (isCheckBox && isActive.indexOf(index) != -1),
                  }" @click="handleClick(index)">
									{{ item || "" }} <span
										v-if="showItemNum&&item[ItemNumKey]">({{item[ItemNumKey]}})</span>
								</span>
							</template>
							<template>
								<span v-if="item[keyName]" :class="{
                    active:
                      (!isCheckBox && isActive === index) ||
                      (isCheckBox && isActive.indexOf(index) != -1),
                  }" @click="handleClick(index)">
									{{ item[keyName] || "" }} <span
										v-if="showItemNum&&item[ItemNumKey]">({{item[ItemNumKey]}})</span>
								</span>
							</template>
						</div>
					</div>
				</div>

				<div v-if="show_open" class="zksh" @click="open = !open" :style="textStyle">
					<span>{{ open ? "收起" : "展开" }}</span>
					<i :class="{ t_deg180: open }" style="transition: transform 0.5s" class="el-icon-arrow-down"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		layout: "collapse-tab",
		model: {
			prop: "value",
			event: "change",
		},
		props: {
			value: {
				type: [Number, String, Array],
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			content: {
				type: String,
				default: () => {},
			},
			list: {
				type: Array,
				default: () => [],
			},
			num: {
				type: Boolean,
				default: false,
			},
			width: {
				type: String,
				default: "",
			},
			showTitle: {
				type: Boolean,
				default: false,
			},
			showOpen: {
				type: Boolean,
				default: true,
			},
			auto: {
				type: Boolean,
				default: false,
			},
			keyName: {
				type: String,
				default: "name",
			},
			isCheckBox: {
				//是否多选
				type: Boolean,
				default: false,
			},
			lineHeight: {
				type: [Number, String],
				default: "20px",
			},
			beforePb: {
				type: [Number, String],
				default: "10px",
			},
			// 显示选项数量
			showItemNum: {
				type: Boolean,
				default: false,
			},
			ItemNumKey: {
				type: String,
				default: "num",
			}
		},
		data() {
			return {
				open: false,
				isActive: this.value,
				show_open: this.showOpen,
				tablistId: false,
			};
		},
		computed: {
			textStyle() {
				return {
					lineHeight: this.lineHeight,
				};
			},
			groupStyle() {
				let height = "";
				if (this.open) height = "auto";
				else if (this.lineHeight == 1) height = "30px";
				else height = `calc(${this.lineHeight} + 10px)`;
				return {
					height,
				};
			},
		},
		watch: {
			list: {
				handler(newVal, oldVal) {
					if (this.auto) {
						this.isActive = "";
					}
					this.getIsMore();
				},
				deep: true,
			},
			value(newVal) {
				this.isActive = newVal;
			},
		},
		created() {
			// 获取随机数
		},
		mounted() {
			this.tablistId = `tablist${Math.floor(Math.random() * 100000000)}`;
			this.getIsMore();
		},
		methods: {
			getIsMore() {
				if (!this.showOpen) {
					// 主动控制 不再显示
					return false;
				}
				this.$nextTick(() => {
					let tablist = document.getElementById(this.tablistId);
					if (tablist) {
						let max = Number(this.lineHeight.substring(0, this.lineHeight.length - 2)) + 10;
						if (!max) max = 32;
						this.show_open = tablist.offsetHeight > max;
					} else {}
				});
			},
			// 获取多选数据
			getCheckList() {
				let list = [];
				this.isActive.forEach((item) => {
					list.push(this.list[item]);
				});
				return list;
			},
			handleClick(index) {
				// 多选
				if (this.isCheckBox) {
					if (this.isActive.indexOf(index) == -1) {
						if (this.isActive) this.isActive.push(index);
						else {
							this.isActive = [index];
						}
					} else {
						let i = this.isActive.indexOf(index);
						this.isActive.splice(i, 1);
					}
					let checkList = this.getCheckList();
					this.$emit("change", this.isActive);
					this.$emit("tabChange", {
						data: checkList,
					});
					return;
				}
				// 单选 
				if (this.isActive == index && (this.isActive != "" || this.isActive === 0)) {
					this.isActive = "";
				} else this.isActive = index;
				let item = this.list[this.isActive];
				this.$emit("itemClicked", item, index); // 触发自定义事件，并传递 参数 给父组件
				this.$emit("change", this.isActive);
				this.$emit("tabChange", {
					index: index,
					data: item,
				});
			},
		},
	};
</script>

<style lang="scss">
	.poppers {
		// font-weight: 400;
		// font-size: 13px !important;
		// color: #354053;
		// box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.11);
		padding: 8px 10px !important;
	}

	/* .main_tit .el-popover .popper__arrow{
		display: none;
	} */
</style>

<style scoped>
	.main_tit .el-tooltip__popper {
		border-color: #fff;
		color: red;
	}

	.height30 {
		height: 30px;
	}

	.active {
		color: #1078f9;
		border-bottom: 1px solid #1078f9;
	}

	.collapse_tab {
		width: 100%;
		margin: 0 auto;
		border-radius: 5px 5px 5px 5px;
		background-color: #fff;
	}

	.main_zc {
		display: flex;
		font-size: 14px;
		margin: 0 15px;
		border-bottom: 1px solid #f5f5f5;
		padding-top: 10px;
	}

	.main_tit {
		margin-right: 30px;
		color: #767676;
		font-size: 14px;
		width: 80px;
	}

	.tab-list-group {
		flex: 1;
		overflow: hidden;
	}

	.main_text {
		display: flex;
		/* flex: 1 的作用是让子元素在 Flexbox 布局中平均分配剩余空间 */
		align-items: center;
		flex-wrap: wrap;
	}

	.main_box {
		margin-right: 25px;
		padding-bottom: 10px;
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
	}

	.zksh {
		color: #1078f9;
		cursor: pointer;
	}

	.t_deg180 {
		transform: rotate(180deg);
	}
</style>