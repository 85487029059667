import Vue from 'vue'
// import {
// 	login,

// 	getIp,
// 	oauthToken
// } from '@/api/user';
// addSchoolUser,
// getUserInfo,


import {
	getAccessToken,
	removeAccessToken,
	setAccessToken
} from '@/utils/accessToken';

import AES from "@/utils/AES.js";

import {
	title,
	tokenName
} from '@/config/setting.config';

import {
	setStorage,
	getStorage
} from '@/utils/index'

import {
	resetRouter
} from '@/router';



const state = {
	accessToken: getAccessToken() || "",
	username: getStorage('username'),
	avatar: '',
	permissions: [],
	ip: '',
	HbnRefreshToken: '',
	HbnExpiresIn: '',
	userinfo: ''
};

const getters = {
	userinfo: (state) => state.userinfo,
};
const mutations = {
	setUserInfo(state, data) {
		state.userinfo = data
	},
};
const actions = {
	setPermissions({
		commit
	}, permissions) {
		commit('setPermissions', permissions);
	},
	async logout({
		dispatch
	}) {
		// await logout(state.accessToken);
		await dispatch('resetAccessToken');
		await resetRouter();
	},
};
export default {
	state,
	getters,
	mutations,
	actions
};