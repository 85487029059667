import request from "@/utils/request.js";


// 登录二维码
export function web_login(data) {
	return request({
		url: "/api/indexnew/web_login",
		method: "GET",
		data,
	});
}
//登录
export function login(data) {
	return request({
		url: "/api/indexnew/login",
		method: "POST",
		data,
	});
}
//发送邮箱验证码
export function send_email(data) {
	return request({
		url: "/api/indexnew/send_email",
		method: "POST",
		data,
	});
}


//首页数据
export function index(data) {
	return request({
		url: "/api/demo/index",
		method: "POST",
		data,
	});
}
//动态列表
export function trends_list(data) {
	return request({
		url: "/api/demo/trends_list",
		method: "POST",
		data,
	});
}
//动态详情
export function trends_detail(data) {
	return request({
		url: "/api/demo/trends_detail",
		method: "POST",
		data,
	});
}

//政策知识库
export function knowledge_list(data) {
	return request({
		url: "/api/demo/knowledge_list",
		method: "POST",
		data,
	});
}

//政策知识库详情
export function knowledge_detail(data) {
	return request({
		url: "/api/demo/knowledge_detail",
		method: "POST",
		data,
	});
}

//最新政策
export function get_policynew(data) {
	return request({
		url: "/api/demo/get_policynew",
		method: "POST",
		data,
	});
}

//正在申报
export function get_information(data) {
	return request({
		url: "/api/demo/get_information",
		method: "POST",
		data,
	});
}

//公示其它 
export function bulletin_list(data) {
	return request({
		url: "/api/demo/bulletin_list",
		method: "POST",
		data,
	});
}

//政策详情
export function depot_detail(data) {
	return request({
		url: "/api/demo/depot_detail",
		method: "POST",
		data,
	});
}


//惠企服务列表
export function service_manage(data) {
	return request({
		url: "/api/demo/service_manage",
		method: "POST",
		data,
	});
}


//惠企服务分类
export function service_type(data) {
	return request({
		url: "/api/demo/service_type",
		method: "POST",
		data,
	});
}

//惠企服务详情
export function service_manage_detail(data) {
	return request({
		url: "/api/demo/service_manage_detail",
		method: "POST",
		data,
	});
}

export function service_enterprise_list(data) {
	return request({
		url: "/api/demo/service_enterprise_list",
		method: "POST",
		data,
	});
}

//二级地图详情
export function get_total_map(data) {
	return request({
		url: "/api/demo/get_total_map",
		method: "POST",
		data,
	});
}

//惠企服务申请服务
export function service_record(data) {
	return request({
		url: "/api/demo/service_record",
		method: "POST",
		data,
	});
}

//提交诉求  诉求类型
export function appeal_type(data) {
	return request({
		url: "/api/demo/appeal_type",
		method: "POST",
		data,
	});
}

//提交诉求
export function appeal_tabulation(data) {
	return request({
		url: "/api/demo/appeal_tabulation",
		method: "POST",
		data,
	});
}


//人才类型
export function talent_type(data) {
	return request({
		url: "/api/demo/talent_type",
		method: "POST",
		data,
	});
}


//人才列表
export function talent_manage_list(data) {
	return request({
		url: "/api/demo/talent_manage_list",
		method: "POST",
		data,
	});
}

//人才详情
export function talent_manage_detail(data) {
	return request({
		url: "/api/demo/talent_manage_detail",
		method: "POST",
		data,
	});
}

//专家所属院校
export function expert_manage_first(data) {
	return request({
		url: "/api/demo/expert_manage_first",
		method: "POST",
		data,
	});
}

//专家所属学院
export function expert_manage_two(data) {
	return request({
		url: "/api/demo/expert_manage_two",
		method: "POST",
		data,
	});
}

//专家所属地区
export function expert_manage_area(data) {
	return request({
		url: "/api/demo/expert_manage_area",
		method: "POST",
		data,
	});
}
//专家列表
export function expert_manage_list(data) {
	return request({
		url: "/api/demo/expert_manage_list",
		method: "POST",
		data,
	});
}

//专家详情
export function expert_manage_detail(data) {
	return request({
		url: "/api/demo/expert_manage_detail",
		method: "POST",
		data,
	});
}

//专家详情  专利
export function expert_zjpatent(data) {
	return request({
		url: "/api/demo/expert_zjpatent",
		method: "POST",
		data,
	});
}

//专家论文详情
export function expert_paper_detail(data) {
	return request({
		url: "/api/demo/expert_paper_detail",
		method: "POST",
		data,
	});
}

//服务体系
export function fwystem(data) {
	return request({
		url: "/api/demo/fwystem",
		method: "POST",
		data,
	});
}
//政策库  发布单位
export function unit_list(data) {
	return request({
		url: "/api/demo/unit_list",
		method: "POST",
		data,
	});
}

//政策库  发布时间
export function time_list(data) {
	return request({
		url: "/api/demo/time_list",
		method: "POST",
		data,
	});
}
//政策库  文件等级
export function grade_list(data) {
	return request({
		url: "/api/demo/grade_list",
		method: "POST",
		data,
	});
}

//政策库  行业划分
export function hyhf_list(data) {
	return request({
		url: "/api/demo/hyhf_list",
		method: "POST",
		data,
	});
}

//政策库  政策列表
export function depot_list(data) {
	return request({
		url: "/api/demo/depot_list",
		method: "POST",
		data,
	});
}
//政策库  政策图谱
export function policy_atlas(data) {
	return request({
		url: "/api/demo/policy_atlas",
		method: "POST",
		data,
	});
}

//专家职称
export function label_list(data) {
	return request({
		url: "/api/index/label_list",
		method: "POST",
		data,
	});
}

//专家入驻
export function expert_settle_Settle(data) {
	return request({
		url: "/api/demo/expert_settle_Settle",
		method: "POST",
		data,
	});
}


//专利类型列表
export function zl_cat_list(data) {
	return request({
		url: "/api/demo/zl_cat_list",
		method: "POST",
		data,
	});
}


//人才入驻
export function talent_settle(data) {
	return request({
		url: "/api/demo/talent_settle",
		method: "POST",
		data,
	});
}

//人才入驻
export function expert_message(data) {
	return request({
		url: "/api/demo/expert_message",
		method: "POST",
		data,
	});
}

//政策匹配  企业类型
export function get_com_type(data) {
	return request({
		url: "/api/webgrab/get_com_type",
		method: "POST",
		data,
	});
}

//政策匹配  所属行业
export function get_industry(data) {
	return request({
		url: "/api/webgrab/get_industry",
		method: "POST",
		data,
	});
}
//政策匹配  获取列表
export function matching_policy(data) {
	return request({
		url: "/api/webgrab/matching_policy",
		method: "POST",
		data,
	});
}

//融资服务
export function financingservices(data) {
	return request({
		url: "/api/index/financingservices",
		method: "POST",
		data,
	});
}


//融资服务详情
export function financingservices_detail(data) {
	return request({
		url: "/api/index/financingservices_detail",
		method: "POST",
		data,
	});
}
//融资服务 申请服务
export function financingapply(data) {
	return request({
		url: "/api/index/financingapply",
		method: "POST",
		data,
	});
}

//融资服务 服务类型
export function financing_type(data) {
	return request({
		url: "/api/index/financing_type",
		method: "POST",
		data,
	});
}

//融资服务 贷款额度
export function limit_list(data) {
	return request({
		url: "/api/index/limit_list",
		method: "POST",
		data,
	});
}

//融资服务 还款方式
export function way_list(data) {
	return request({
		url: "/api/index/way_list",
		method: "POST",
		data,
	});
}

//诉求公示
export function appeal_publicity(data) {
	return request({
		url: "/api/demo/appeal_publicity",
		method: "POST",
		data,
	});
}
//提交诉求列表
export function appeal_trends(data) {
	return request({
		url: "/api/demo/appeal_trends",
		method: "POST",
		data,
	});
}

//身份申请
export function market_sfapply(data) {
	return request({
		url: "/api/index/market_sfapply",
		method: "POST",
		data,
	});
}

//对接动态
export function market_demand_release(data) {
	return request({
		url: "/api/index/market_demand_release",
		method: "POST",
		data,
	});
}

//供方市场
export function market_supply_shoplist(data) {
	return request({
		url: "/api/index/market_supply_shoplist",
		method: "POST",
		data,
	});
}

//需求市场
export function market_demand_shoplist(data) {
	return request({
		url: "/api/index/market_demand_shoplist",
		method: "POST",
		data,
	});
}


//行业动态
export function market_industry_trends(data) {
	return request({
		url: "/api/index/market_industry_trends",
		method: "POST",
		data,
	});
}


//行业动态分类
export function market_industry_type(data) {
	return request({
		url: "/api/index/market_industry_type",
		method: "POST",
		data,
	});
}

//需求商品详情
export function market_demand_shoplist_detail(data) {
	return request({
		url: "/api/index/market_demand_shoplist_detail",
		method: "POST",
		data,
	});
}

//供方商品详情
export function market_supply_shoplist_detail(data) {
	return request({
		url: "/api/index/market_supply_shoplist_detail",
		method: "POST",
		data,
	});
}

//需求方留言咨询
export function market_demand_seek(data) {
	return request({
		url: "/api/index/market_demand_seek",
		method: "POST",
		data,
	});
}

//供应方留言咨询
export function market_supply_seek(data) {
	return request({
		url: "/api/index/market_supply_seek",
		method: "POST",
		data,
	});
}

//知识产权  专利类型
export function patent_cat(data) {
	return request({
		url: "/api/index/patent_cat",
		method: "POST",
		data,
	});
}

//知识产权  所属行业
export function enterprise_industry(data) {
	return request({
		url: "/api/index/enterprise_industry",
		method: "POST",
		data,
	});
}

//知识产权 列表
export function property_list(data) {
	return request({
		url: "/api/index/property_list",
		method: "POST",
		data,
	});
}

//企业详情
export function enterprise_detailpc(data) {
	return request({
		url: "/api/enterprise/enterprise_detailpc",
		method: "POST",
		data,
	});
}


//企业详情  政策信息
export function enterprise_support(data) {
	return request({
		url: "/api/enterprise/enterprise_support",
		method: "POST",
		data,
	});
}


//企业详情  股东列表
export function shareholder_list(data) {
	return request({
		url: "/api/enterprise/shareholder_list",
		method: "POST",
		data,
	});
}

//企业详情  专利类型
export function patent_type(data) {
	return request({
		url: "/api/enterprise/patent_type",
		method: "POST",
		data,
	});
}

//企业详情  专利状态
export function patent_status(data) {
	return request({
		url: "/api/enterprise/patent_status",
		method: "POST",
		data,
	});
}

//企业详情  申请年份
export function patent_year(data) {
	return request({
		url: "/api/index/patent_year",
		method: "POST",
		data,
	});
}

//企业详情  申请年份
export function patent_years(data) {
	return request({
		url: "/api/enterprise/patent_year",
		method: "POST",
		data,
	});
}

//企业详情  专利列表
export function patent_list(data) {
	return request({
		url: "/api/enterprise/patent_list",
		method: "POST",
		data,
	});
}

//企业详情  商标列表
export function brand_list(data) {
	return request({
		url: "/api/enterprise/brand_list",
		method: "POST",
		data,
	});
}

//企业详情  软著列表
export function softright_list(data) {
	return request({
		url: "/api/enterprise/softright_list",
		method: "POST",
		data,
	});
}


//企业详情  作品列表
export function copyright_list(data) {
	return request({
		url: "/api/enterprise/copyright_list",
		method: "POST",
		data,
	});
}

//企业详情  资质证书列表
export function qualification_list(data) {
	return request({
		url: "/api/enterprise/qualification_list",
		method: "POST",
		data,
	});
}

//企业详情  人员信息
export function person_list(data) {
	return request({
		url: "/api/enterprise/person_list",
		method: "POST",
		data,
	});
}

//企业详情  人员信息
export function check_list(data) {
	return request({
		url: "/api/enterprise/check_list",
		method: "POST",
		data,
	});
}

//企业详情  招投标数量
export function tender_count(data) {
	return request({
		url: "/api/enterprise/tender_count",
		method: "POST",
		data,
	});
}

//企业详情  招投标列表
export function tender_list(data) {
	return request({
		url: "/api/enterprise/tender_list",
		method: "POST",
		data,
	});
}
//企业详情  经营异常
export function jyabnormal_list(data) {
	return request({
		url: "/api/enterprise/jyabnormal_list",
		method: "POST",
		data,
	});
}

//企业详情  行政处罚
export function jypunish_list(data) {
	return request({
		url: "/api/enterprise/jypunish_list",
		method: "POST",
		data,
	});
}

//企业详情  联系方式
export function contract_list(data) {
	return request({
		url: "/api/enterprise/contract_list",
		method: "POST",
		data,
	});
}

//企业详情  联系方式数量
export function contract_count(data) {
	return request({
		url: "/api/enterprise/contract_count",
		method: "POST",
		data,
	});
}

//企业详情  成果转化
export function achievement_list(data) {
	return request({
		url: "/api/index/achievement_list",
		method: "POST",
		data,
	});
}

//供方市场分类
export function market_type(data) {
	return request({
		url: "/api/index/market_type",
		method: "POST",
		data,
	});
}

//供方市场分类
export function market_demand_type(data) {
	return request({
		url: "/api/index/market_demand_type",
		method: "POST",
		data,
	});
}

//全局配置
export function pcconfig(data) {
	return request({
		url: "/api/index/pcconfig",
		method: "POST",
		data,
	});
}

//PC扫码响应
export function checkStatus(data) {
	return request({
		url: "/api/indexnew/checkStatus",
		method: "POST",
		data,
	});
}
//获取个人信息
export function user_detail(data) {
	return request({
		url: "/api/Person/user_detail1",
		method: "POST",
		data,
	});
}
//编辑个人信息
export function edit_user(data) {
	return request({
		url: "/api/Person/edit_user",
		method: "POST",
		data,
	});
}


//企业认证
export function qiyeauthentication(data) {
	return request({
		url: "/api/Person/qiyeauthentication",
		method: "POST",
		data,
	});
}

//注册
export function phoneregister(data) {
	return request({
		url: "/api/indexnew/phoneregister",
		method: "POST",
		data,
	});
}

//忘记密码
export function find_password(data) {
	return request({
		url: "/api/indexnew/find_password",
		method: "POST",
		data,
	});
}
//忘记密码
export function find_password_tt(data) {
	return request({
		url: "/api/indexnew/find_password_tt",
		method: "POST",
		data,
	});
}

//绑定邮箱
export function email_bind(data) {
	return request({
		url: "/api/Person/email_bind",
		method: "POST",
		data,
	});
}


//绑定邮箱合并
export function change_user(data) {
	return request({
		url: "/api/Person/change_user",
		method: "POST",
		data,
	});
}

//生成验证二维码
export function web_login1(data) {
	return request({
		url: "/api/Person/web_login1",
		method: "POST",
		data,
	});
}

//二维码相应
export function checkStatus1(data) {
	return request({
		url: "/api/Person/checkStatus1",
		method: "POST",
		data,
	});
}

//pc扫码认证
export function getUserInfo1(data) {
	return request({
		url: "/index/index/getUserInfo1",
		method: "POST",
		data,
	});
}

//更改绑定
export function unionid_change_user(data) {
	return request({
		url: "/api/Person/unionid_change_user",
		method: "POST",
		data,
	});
}

//手机号验证
export function mobile_bind(data) {
	return request({
		url: "/api/Person/mobile_bind",
		method: "POST",
		data,
	});
}


//手机号合并
export function mobile_change_user(data) {
	return request({
		url: "/api/Person/mobile_change_user",
		method: "POST",
		data,
	});
}


//文件管理  文档类型
export function document_types(data) {
	return request({
		url: "/api/Person/document_types",
		method: "POST",
		data,
	});
}


//文件管理  文档分类
export function document_classification(data) {
	return request({
		url: "/api/Person/document_classification",
		method: "POST",
		data,
	});
}

//文件管理  文档归属
export function document_belong(data) {
	return request({
		url: "/api/Person/document_belong",
		method: "POST",
		data,
	});
}

//文件管理  文档列表
export function document_liett(data) {
	return request({
		url: "/api/Person/document_liett",
		method: "POST",
		data,
	});
}

//文件管理  文档删除
export function delete_document_liett(data) {
	return request({
		url: "/api/Person/delete_document_liett",
		method: "POST",
		data,
	});
}

//公司咨询
export function company_seek(data) {
	return request({
		url: "/api/Person/company_seek",
		method: "POST",
		data,
	});
}

//公司咨询
export function company_seeks(data) {
	return request({
		url: "/api/demo/company_seek",
		method: "POST",
		data,
	});
}

//行业管理
export function industry(data) {
	return request({
		url: "/api/demo/industry",
		method: "POST",
		data,
	});
}

//公司咨询
export function service_records(data) {
	return request({
		url: "/api/Person/service_record",
		method: "POST",
		data,
	});
}

//专家联系
export function expert_messages(data) {
	return request({
		url: "/api/Person/expert_message",
		method: "POST",
		data,
	});
}
//人才联系
export function talent_applyfor(data) {
	return request({
		url: "/api/Person/talent_applyfor",
		method: "POST",
		data,
	});
}

//政策申报
export function depotreport(data) {
	return request({
		url: "/api/Person/depotreport",
		method: "POST",
		data,
	});
}

//融资服务
export function financingapplys(data) {
	return request({
		url: "/api/Person/financingapply",
		method: "POST",
		data,
	});
}

//供需需求
export function market_demand_releases(data) {
	return request({
		url: "/api/Person/market_demand_release",
		method: "POST",
		data,
	});
}

//专家入驻
export function expert_settles(data) {
	return request({
		url: "/api/Person/expert_settle",
		method: "POST",
		data,
	});
}

//人才入驻
export function talent_settles(data) {
	return request({
		url: "/api/Person/talent_settle",
		method: "POST",
		data,
	});
}

//诉求记录
export function appeal_tabulations(data) {
	return request({
		url: "/api/Person/appeal_tabulation",
		method: "POST",
		data,
	});
}

//年费监控  导出
export function achievement_daochu(data) {
	return request({
		url: "/api/index/achievement_daochu",
		method: "POST",
		data,
	});
}

//续费
export function renew(data) {
	return request({
		url: "/api/enterprise/renew",
		method: "POST",
		data,
	});
}

//资质证书导出
export function qualification_daochu(data) {
	return request({
		url: "/api/enterprise/qualification_daochu",
		method: "POST",
		data,
	});
}

//企业信息变更
export function qyedit(data) {
	return request({
		url: "/api/Person/qyedit",
		method: "POST",
		data,
	});
}

//动态详情
export function newfeed_detail(data) {
	return request({
		url: "/api/demo/newfeed_detail",
		method: "POST",
		data,
	});
}

//政策申报
export function depotreports(data) {
	return request({
		url: "/api/demo/depotreport",
		method: "POST",
		data,
	});
}

//产权咨询
export function propertyseek(data) {
	return request({
		url: "/api/demo/propertyseek",
		method: "POST",
		data,
	});
}

//职位列表
export function service_position(data) {
	return request({
		url: "/api/demo/service_position",
		method: "POST",
		data,
	});
}

//融资服务  合作伙伴
export function partners(data) {
	return request({
		url: "/api/index/partners",
		method: "POST",
		data,
	});
}

//供方轮播图
export function market_supply_show(data) {
	return request({
		url: "/api/demo/market_supply_show",
		method: "POST",
		data,
	});
}

//需求方轮播图
export function market_demand_show(data) {
	return request({
		url: "/api/demo/market_demand_show",
		method: "POST",
		data,
	});
}

//申报详情
export function information_detail(data) {
	return request({
		url: "/api/demo/information_detail",
		method: "POST",
		data,
	});
}

//公示其它详情
export function bulletin_detail(data) {
	return request({
		url: "/api/demo/bulletin_detail",
		method: "POST",
		data,
	});
}

//发布供应
export function fb_market_demand_release(data) {
	return request({
		url: "/api/index/fb_market_demand_release",
		method: "POST",
		data,
	});
}

//发送手机号验证码
export function dx_send(data) {
	return request({
		url: "/api/user/dx_send",
		method: "POST",
		data,
	});
}

//文档录入
export function add_document_liett(data) {
	return request({
		url: "/api/Person/add_document_liett",
		method: "POST",
		data,
	});
}

//验证码验证
export function yz_xx(data) {
	return request({
		url: "/api/indexnew/yz_xx",
		method: "POST",
		data,
	});
}

//动态详情
export function market_industry_trends_detail(data) {
	return request({
		url: "/api/index/market_industry_trends_detail",
		method: "POST",
		data,
	});
}

//行业管理
export function industryss(data) {
	return request({
		url: "/api/demo/industry",
		method: "POST",
		data,
	});
}

//获取类型
export function area_list(data) {
	return request({
		url: "/api/demo/area_list",
		method: "POST",
		data,
	});
}

//获取类型
export function rclx_list(data) {
	return request({
		url: "/api/demo/rclx_list",
		method: "POST",
		data,
	});
}
//添加产权
export function add_propertyright(data) {
	return request({
		url: "/api/demo/add_propertyright",
		method: "POST",
		data,
	});
}
//省市区
export function qy_area_list(data) {
	return request({
		url: "/api/demo/qy_area_list",
		method: "POST",
		data,
	});
}

export function get_newfeed_list(data) {
	return request({
		url: "/api/index/get_newfeed_list",
		method: "POST",
		data,
	});
}

export function talent_applyfors(data) {
	return request({
		url: "/api/demo/talent_applyfor",
		method: "POST",
		data,
	});
}

export function list_count(data) {
	return request({
		url: "/api/Enterprise/list_count",
		method: "POST",
		data,
	});
}

export function zx_unit(data) {
	return request({
		url: "/api/index/zx_unit",
		method: "POST",
		data,
	});
}

export function zx_zx_level(data) {
	return request({
		url: "/api/index/zx_level",
		method: "POST",
		data,
	});
}

export function depot_file_detail(data) {
	return request({
		url: "/api/Enterprise/depot_file_detail",
		method: "POST",
		data,
	});
}

export function get_supportlevel_list(data) {
	return request({
		url: "/api/index/get_supportlevel_list",
		method: "POST",
		data,
	});
}

//删除知识产权
export function del_propertyright(data) {
	return request({
		url: "/api/demo/del_propertyright",
		method: "POST",
		data,
	});
}


//正在申报
export function sb_project(data) {
	return request({
		url: "/api/Person/sb_project",
		method: "POST",
		data,
	});
}

//正在申报
export function reportproject_list(data) {
	return request({
		url: "/api/index/reportproject_list",
		method: "POST",
		data,
	});
}

//记录提醒
export function edit_notice_time(data) {
	return request({
		url: "/api/person/edit_notice_time",
		method: "POST",
		data,
	});
}

//成果转化  专利类型
export function patent_cat_list(data) {
	return request({
		url: "/api/Achievement/patent_cat_list",
		method: "POST",
		data,
	});
}

//成果转化  专利类型状态
export function patent_status_list(data) {
	return request({
		url: "/api/Achievement/patent_status_list",
		method: "POST",
		data,
	});
}

//成果转化  申请时间
export function patent_year_list(data) {
	return request({
		url: "/api/Achievement/patent_year_list",
		method: "POST",
		data,
	});
}

//成果转化  专利列表
export function achievement_patent_list(data) {
	return request({
		url: "/api/Achievement/achievement_patent_list",
		method: "POST",
		data,
	});
}

//成果转化  商标列表
export function achievement_brand_list(data) {
	return request({
		url: "/api/Achievement/achievement_brand_list",
		method: "POST",
		data,
	});
}

//成果转化  软著列表
export function achievement_softright_list(data) {
	return request({
		url: "/api/Achievement/achievement_softright_list",
		method: "POST",
		data,
	});
}

//成果转化  作品列表
export function achievement_copyright_list(data) {
	return request({
		url: "/api/Achievement/achievement_copyright_list",
		method: "POST",
		data,
	});
}

