import index from "../pages/index/index";
// import login from '../pages/login'
// 白名单
var constRouters = [
  // {
  //   path: '/login',
  //   name: '登录页',
  //   component: login
  // },
  {
    path: "/index",
    name: "首页",
    component: index,
  }
];
export default constRouters;
