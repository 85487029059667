<template>
  <div />
</template>

<script>
	export default {
		data() {
			return {
				
			};
		},
		methods:{
			
		},
	};
</script>

<style>
	
</style>